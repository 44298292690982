import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLocale } from 'store/intl/intl.selectors';

import { intl } from './config';

interface Props {
  children: ReactNode;
}

const IntlSelectorProvider = ({ children }: Props) => {
  const locale = useSelector(getLocale);

  return (
    <IntlProvider key={locale} locale={locale} messages={intl[locale].messages}>
      {children}
    </IntlProvider>
  );
};

export default IntlSelectorProvider;
