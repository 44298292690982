import { Box, CircularProgress } from '@mui/material';

const LoadingState = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexGrow="1"
    height="100%"
  >
    <CircularProgress />
  </Box>
);

export default LoadingState;
